import apiClient from '@/services/api/apiClient';

export default {
  getBaseInfo: (lang: string): Promise<any> => apiClient.get(`/base_info?lang=${lang}`),
  getProjects: (lang: string, params: any): Promise<any> => {
    return apiClient.get(`/projects`, {
      params: {
        lang: lang,
        ...(params.page ? {page: params.page} : {}),
        ...(params.pageSize ? {page_size: params.pageSize} : {}),
        ...(params.typeId ? {type_id : params.typeId} : {})
      }
    })
  },
  getProject: (id: string, lang: string): Promise<any> => apiClient.get(`/projects/full/${id}?lang=${lang}`),
  getProjectsTypes: (lang: string): Promise<any> => apiClient.get(`/projects/project_types?lang=${lang}`),

  getVideos: (params: any): Promise<any> => apiClient.get(`/projects/videos`, {params}),

  getVideoTypes: (lang: string): Promise<any> => apiClient.get(`/projects/media_types?lang=${lang}`),
  getUpgrades: (lang: string): Promise<any> => apiClient.get(`/news?lang=${lang}&is_main`),

  getNews: (params: any): Promise<any> => apiClient.get('/news', { params }),

  getOneNews: (id: string, lang: string): Promise<any> => apiClient.get(`/news/${id}?lang=${lang}`),
  sendFeedback: (message: any): Promise<any> => apiClient.post('/send_feedback', message),
}
