<template>
  <section class="wrapper">
    <p
      class="address"
      v-if="isAddress"
    >
      <a href="https://yandex.ru/maps/-/CCUiq8FiPB" target="_blank">{{baseInfo && baseInfo.address}}</a>
    </p>
    <p
      class="phone"
      v-if="isPhone"
    ><a :href="`tel:${baseInfo && baseInfo.phone}`">{{baseInfo && baseInfo.phone}}</a></p>
    <a
      class="yt"
      :href="baseInfo && baseInfo.youtube"
      target="_blank"
    ></a>
    <a
      class="ig"
      :href="baseInfo &&  baseInfo.instagram"
      target="_blank"
    ></a>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import youtube from '@/assets/img/yt-black.svg'
import instagram from '@/assets/img/ig-black.svg'

export default {
  name: 'ContactsBlock',

  data () {
    return {
      youtube,
      instagram,
    }
  },

  computed: {
    ...mapState(['baseInfo']),
  },

  props: {
    isAddress: {
      type: Boolean,
      default: false,
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
}

.address,
.phone {
  margin-right: 27px;
}
.address {
  a{
    font-size: 14px;
    text-decoration: none;
    color: inherit;
  }
}

.phone {
  a {
    text-decoration: none;
    font-size: inherit;
    color: inherit;
  }
}
.social-link {
  max-width: 27px !important;
  width: 27px;
  height: 27px;
  cursor: pointer;
  margin-right: 10px;
}

.social-link-last {
  margin-right: 0;
}

.ig {
  width: 27px;
  height: 27px;
  background-image: url("../assets/img/ig-black.svg") !important;
}

.yt {
  width: 27px;
  height: 27px;
  margin-right: 10px;
  background-image: url("../assets/img/yt-black.svg") !important;
}

@media screen and (max-width: 1100px) {
  .address {
    display: none;
  }
}

@media (hover: hover) and (pointer: fine) {
  .phone, .address {
    a {
      &:hover {
        color: $green !important;
      }
    }
  }
  .yt {
    &:hover {
      background-image: url("../assets/img/yt.svg") !important;
      cursor: pointer;
    }
  }
  .ig {
    &:hover {
      background-image: url("../assets/img/ig.svg") !important;
      cursor: pointer;
    }
  }
}
</style>
