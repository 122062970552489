import Vue from 'vue';
import Vuex from 'vuex';
import projects from '@/store/projects/index'
import mediaProjects from '@/store/mediaProjects/index'
import news from '@/store/news/index'
import feedback from '@/store/feedback/index'
import api from '@/services/api/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appWidth: false,
    lang: 'ru',
    baseInfo: null,
    swiperOptions: {
      speed: 450,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        draggable: true,
        dynamicBullets: true
      },
      slidesPerView: 'auto',
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
      },
    },
    alert: { message: null, isAlert: false , color: 'success'},
    scrollY: 0,
    tag: null,
  },
  mutations: {
    setAppWidth(state, value) {
      state.appWidth = value
    },
    setLang(state, value) {
      state.lang = value
    },
    setBaseInfo(state, value) {
      state.baseInfo = value
    },
    setAlert(state, value) {
      state.alert = value
    },
    setScrollY(state, value) {
      state.scrollY = value
    },
    setTag(state, value) {
      state.tag = value
    },
  },
  actions: {
    async getBaseInfo({ commit, rootState }) {
      try {
        const res = await api.getBaseInfo(rootState.lang)
        commit('setBaseInfo', res.data)
        return res
      } catch (error) {
        return error
      }
    },
    async sendFeedback(context, message) {
      try {
        return await api.sendFeedback(message)
      } catch (error) {
        return error
      }
    },
  },
  modules: {
    projects,
    mediaProjects,
    news,
    feedback
  },
});
