<template>
  <section>
    <div class="header-background"></div>
    <div class="main-container">
      <v-app class="background">
        <section class="main-layout">
          <v-snackbar
            id="snackbar"
            :value="alert.isAlert"
            top
            right
            timeout="10000"
            light
            :color="alert.color"
          >
            {{alert.message}}
            <template v-slot:action="{ attrs }">
              <v-btn
                icon
                @click="setAlert({isAlert: false})"
              >
                <v-icon class="close-icon">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>

          <Header />

          <v-main class="main">
            <router-view />
          </v-main>

          <v-lazy
            is="footer"
            :options="{ threshold: 0.3 }"
            min-height="80"
            transition="fade-transition"
          >
            <Footer />
          </v-lazy>
        </section>
      </v-app>
    </div>
  </section>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapMutations, mapState } from 'vuex';
import { recalculateWiderContainers } from "@/helpers/commonHelpers";

export default {
  name: 'MainLayout',

  data () {
    return {
    }
  },

  created () {
    window.addEventListener("resize", recalculateWiderContainers)
    window.addEventListener("scroll", () => {
      recalculateWiderContainers()
    })

    window.addEventListener("load", () => {
      recalculateWiderContainers();
    })
  },

  computed: {
    ...mapState(['alert']),
  },

  methods: {
    ...mapMutations(['setAlert']),
    changeFeedback (val) {
      this.isFeedback = val
    },
  },

  components: { Header, Footer },
};
</script>
<style lang="scss">
@import "src/assets/styles/variables";
.header-background {
  height: 63px;
  position: fixed;
  background-color: white;
  z-index: 2;
  width: 100%;
  left: 0;
}
.main-layout {
  position: relative;
}

.custom-alert {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30%;
}

.main {
  margin-top: 203px;
  margin-bottom: 200px;
}

@media (min-width: 960px) and (max-width: 1100px) {
  .main {
    margin-top: 133px;
  }
}

@media (max-width: 959px) {
  .main {
    margin-top: 137px;
  }
}

@media (max-width: 1100px) {
  .main {
    margin-bottom: 56px;
  }
}
</style>
